import createPlugin from '@@/create-plugin';
import { useEffect } from 'react';
import { markdown } from 'topwrite';
import math from 'remark-math';
import { BuildVisitor, visit } from 'unist-util-visit';
import type { Parent, XBlock, XInline } from 'mdast';

const Component = () => import('./components/tex');

export default createPlugin({
    components: {
        'block:math': Component,
        'block:tex': Component,
        'inline:$': Component,
    },
    activate() {
        useEffect(() => {
            markdown.use(math);
            markdown.use(function() {
                return function(tree) {

                    const visitor: BuildVisitor<Parent, 'math' | 'inlineMath'> = (node, index, parent) => {
                        if (index !== null && parent !== null) {
                            if (node.type === 'math') {

                                const child: XBlock = {
                                    type: 'xBlock',
                                    name: 'math',
                                    value: node.value,
                                    position: node.position
                                };

                                parent.children.splice(index, 1, child);
                            } else if (node.type === 'inlineMath') {

                                const child: XInline = {
                                    type: 'xInline',
                                    name: '$',
                                    value: node.value,
                                    position: node.position
                                };

                                parent.children.splice(index, 1, child);
                            }
                        }
                    };

                    visit(tree, 'math', visitor);
                    visit(tree, 'inlineMath', visitor);
                };
            });
        }, []);

    }
});
